<template>
  <div>
    <b-container class="h-100 mr-auto ml-auto justify-center">
      <b-row align-v="center" class="h-100">
        <b-container>
          <b-row align-h="center" class="login-container">
            <b-row class="mr-0 ml-0">
              <b-col align-self="center">
                <b-container class="login">
                  <main id="login">
                    <section>
                      <a href="/">
                        <AliceLogoMark />
                      </a>
                      <h1 class="login-title">
                        Sign in to turn your data into knowledge
                      </h1>
                      <b-overlay :show="loading" rounded="sm" style="color: var(--color-purple)">
                        <form @submit="submitLogin" v-on:keyup.enter="submitLogin">

                          <p v-if="showUserNotFound" class="check-email-indication color-error-login">{{ textUserNotFound
                          }}
                          </p>
                          <p v-if="showIncorrectCredentials" class="check-email-indication color-error-login">{{
                            textIncorrectCredentials }}</p>

                          <div class="login-form-container">
                            <input type="email" class="login-email" placeholder="Your work email" v-model="email"
                              @input="handleInput" />

                            <input :type="showPassword ? 'text' : 'password'"
                              :class="['login-text', !showPassword ? 'space-letter-pass' : '']"
                              placeholder="Your password" v-model="password" autocomplete="new-password"
                              @input="handleInput" />

                          </div>
                          <input :disabled="loading" @click="submitLogin" type="button" class="login-button"
                            value="Sign in" />
                        </form>
                      </b-overlay>

                      <!-- HIDE/PASSWORD (EYE)  -->
                      <div class="eye-container" v-on:click="showPassword = !showPassword">
                        <PasswordVisibilityToggle :showPassword="showPassword" />
                      </div>

                      <div class="flex forgotten-pass-container">
                        <h3 class="find-password-text" @click="redirectToForgottenPassword">
                          <span>
                            Forgotten password?
                          </span>
                        </h3>
                      </div>

                      <!-- OR -->
                      <LineDividerWithText />

                      <!-- Customizable google button -->
                      <CustomGoogleSignInButton />

                      <ButtonGoogle @on_google_login="handleGoogleButtonClick" />

                      <div class="rememberme-area">
                        <span class="remember-me">
                          <h3>Don’t have an account?</h3>
                          <button class="login-view-togglebtn" @click="navigateToSignUp">
                            <span class="purple-underline">Sign up</span>
                          </button>
                        </span>
                      </div>
                      <PopUpCardModal ref="modalError" :isPopupActive="isPopupActive" :closePopUp="closePopUp"
                        titleCard="Login" :textMsg="textMsgError" :isOk="true" />
                    </section>
                  </main>
                </b-container>
              </b-col>
            </b-row>
          </b-row>
        </b-container>
      </b-row>
    </b-container>
    <footer class="footer-bobble-graph">
      <img src="../assets/Loginbackground.svg" alt="bobble-graph" width="100%" height="100%">
    </footer>
  </div>
</template>
<script>
import { reinitializeSocketWithToken } from "../services/socket_background.js";
import router from "../router";
import ButtonGoogle from "../components/Login/ButtonGoogle.vue";
import { loginUser } from "../services/session";
import { getInitials } from "../utils/textUtilities";
import { mapActions } from "vuex";
import PasswordVisibilityToggle from "../components/Login/PasswordVisibilityToggle.vue";
import AliceLogoMark from "../components/shared/AliceLogoMark";
import LineDividerWithText from '../components/shared/LineDividerWithText.vue';
import PopUpCardModal from "../components/shared/PopUpCardModal.vue";
import CustomGoogleSignInButton from "../components/shared/session/CustomGoogleSignInButton.vue";
import { useAuthWithGoogle } from '../composables/useAuthWithGoogle';

export default {
  name: "login",
  props: {},
  components: {
    ButtonGoogle,
    PasswordVisibilityToggle,
    AliceLogoMark,
    LineDividerWithText,
    PopUpCardModal,
    CustomGoogleSignInButton
  },
  data() {
    console.log("Version 1.1.2")
    return {
      email: null,
      password: null,
      loading: false,
      showPassword: false,
      textMsgError: '',
      isPopupActive: false,
      showUserNotFound: false,
      showIncorrectCredentials: false,
      textUserNotFound: "This email doesn’t exist in our system, please ‘Sign up’ using the link below.",
      textIncorrectCredentials: "Your credentials don't seem to be correct. Please try again, or use the link 'Forgotten password' below.",
    };
  },
  computed: {
    isValidPassword() {
      return this.password?.length >= 8;
    },
  },
  methods: {
    ...mapActions(["setUserSessionAction"]),
    handleGoogleButtonClick(data) {
      const { loginWithGoogle } = useAuthWithGoogle();

      loginWithGoogle(data);
    },
    handleInput() {
      this.resetErrors();
    },
    resetErrors() {
      this.showIncorrectCredentials = false;
      this.showUserNotFound = false;
    },
    navigateToSignUp() {
      router.push({ name: 'sign-up' });
    },
    closePopUp() {
      this.isPopupActive = false;
      this.$refs.modalError.hide();
    },
    async submitLogin() {
      try {
        // if (!this.isValidPassword) return;
        this.loading = true;
        const res_data = await loginUser({
          email: this.email,
          password: this.password,
        });
        if (res_data.error === false) {
          this.loading = false;
          reinitializeSocketWithToken(res_data.token);
          localStorage.setItem("token", res_data.token);
          localStorage.setItem("refresh_token", res_data.refresh_token);
          const save_user = {
            loggedIn: true,
            email: res_data.user.email,
            name: res_data.user.name,
            id_group: res_data.user.id_group,
            initials: getInitials(res_data.user.name),
            image: res_data.user.image,
            permissions: res_data.permissions,
            suscription: res_data.user.suscription
          };
          localStorage.setItem("user_data", JSON.stringify(save_user));
          this.setUserSessionAction(save_user);
          if (res_data?.user?.show_welcome == true) {
            router.push("/editproject?welcome=true");
          } else {
            router.push("/");
          }
        } else {
          this.loading = false;
          if (res_data.message) {
            if (res_data.message === 'user does not exists') {
              this.showUserNotFound = true;
            } else if (res_data.message === 'Email or password is incorrect') {
              this.showIncorrectCredentials = true;
            } else {
              this.textMsgError = res_data.message;
              this.$refs.modalError.showModal();
            }
          } else {
            this.textMsgError = 'An error has occurred.';
            this.$refs.modalError.showModal();
          }
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    redirectToForgottenPassword() {
      this.$router.push({ name: 'forgotten-password' });
    },
  },
};
</script>
<style>
@import "../assets/styles/views/Login.css";
</style>
